.modal-role{
   
}
.custom-role-modal .modal-dialog {
    max-width: 780px;
}
.custom-role-modal .modal-body{
    max-height: 320px; 
  overflow-y: auto; 
}
.parent-border{
border-left: 1px solid gray;
}
.parent-border1{
  border-right: 1px solid gray;
  }
.main-heading-checkbox{
  background-color: #12344d;
  text-align: center;
}

.row-permission{
  display: flex;
    justify-content: flex-start;
    gap: 16px;
}
.subcategories-role{
  display: flex;
  gap: 75px;
}