.container-credentials {
    margin-top: 5%;
}
.form-label-color{
    color: #264966 !important; 
}
.heading-credentials{
    color: #264966 !important; 
    font-weight: 500;
    font-size: 30px;
}
.signup {
    color: black !important;
}

.row-Credentials {
    display: flex;
    justify-content: center;
}

.form-credentials {
    box-shadow: none !important;
    color: #264966;
    border-color: #dee2e6 !important;
}

.inner-container-credentials {
    /* border: 1px solid gray; */
    border-radius: 10px;
    background-color: white;
    padding: 35px;
}

.btn-credentials {
    background-color: #264966 !important;
    width: 100%;
    color: white !important;
}

.btn-credentials:hover {
box-shadow: none !important;
background-color: #2c5f86 !important;
}
.password-toggle {
    position: absolute;
    right: 10px;
    top: 38px; 
    cursor: pointer;
}
.password-toggle-forgotpassword{
    position: absolute;
    right: 10px;
    top: 5px; 
    cursor: pointer;
}
.user-login-credentials{
    cursor: pointer;
    color: #264966 !important;
}
.user-login-credentials:hover{
    color: #2c5f86 !important;
}
.flex-credentials{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}