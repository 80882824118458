.btn-create-dept {
  background-color: #264966 !important;
  color: white !important;
  box-shadow: none !important;
  border-radius: 5px !important;
}

.btn-create-dept:hover {
  background-color: #2c5f86 !important;
  box-shadow: none !important;
}

.btn {
  box-shadow: none !important;
}

.btn-info-agents {
  background-color: #264966 !important;
  color: white !important;
  box-shadow: none !important;
}

.btn-info-agents:hover {
  background-color: #2c5f86 !important;
  box-shadow: none !important;
}

.icons-wrapper {
  display: flex;
  gap: 15px; /* Adjust space between icons */
  justify-content: center;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.eye-icon {
  height: 32px;
    min-width: 32px;
    border-radius: 4px;
    border: 1px solid #cfd7df;
    color: #12344d;
    cursor: pointer;
    padding-top: 5px;
    margin-right: 2px;
    font-size: 14px;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    transition: .1s ease-in;
}

.edit-icon {
  height: 32px;
    min-width: 32px;
    border-radius: 4px;
    border: 1px solid #cfd7df;
    color: #12344d;
    cursor: pointer;
    padding-top: 5px;
    margin-right: 2px;
    font-size: 14px;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    transition: .1s ease-in;
}

.trash-icon {
  height: 32px;
    min-width: 32px;
    border-radius: 4px;
    border: 1px solid #cfd7df;
    color: #12344d;
    cursor: pointer;
    padding-top: 5px;
    margin-right: 2px;
    font-size: 14px;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    transition: .1s ease-in;
}

.eye-icon, .edit-icon, .trash-icon {
  font-size: 15px; /* Icon size */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hover-label {
  background-color: #14233C; /* Tooltip background */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  position: absolute;
  right: calc(100% + 5px); /* Tooltip position */
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none; /* Disable interaction with tooltip */
}

.icon-container:hover .hover-label {
  opacity: 1;
  visibility: visible;
}
