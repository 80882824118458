.kanban-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 20px;
  justify-content: space-between;
  padding: 20px;
  scrollbar-width: thin;
  height: 400px;
}

.kanban-wrapper::-webkit-scrollbar {
  height: 8px;
}

.kanban-wrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.kanban-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.kanban-container {
  flex: 0 0 280px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.kanban-cards-wrapper {
  /* min-height: 300px; */
  padding: 15px;
  background-color: #fff;
  border: 2px dashed #ccc;
  border-radius: 8px;
  overflow-y: auto;
  height: 1000px;
}

.kanban-card {
  background-color: #fff;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: grab;
  transition: transform 0.2s ease;

  height: 200px;
    width: 300px;
    overflow: auto;
}

.kanban-card:hover {
  transform: scale(1.02);
}

.kanban-type {
  padding: 10px 0px;
  color: #fff;
  font-weight: bold;
  border-radius: 25px;
}

.kanban-content-section {
  margin: 10px 0;
}

.kanban-email-row {
  margin-bottom: 5px;
  display: flex;
  /* align-items: center; */
  font-size: 0.9em;
  color: #555;
}
.kanban-email-row-chaild{
  flex-shrink: 0;
  width: 30px;
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.kanban-footer {
  margin-top: -10px;
}

.kanban-footer-item {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  /* margin-right: 10px; */
}

.kanban-footer-agent-priority {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kanban-icon {
  /* margin-right: 6px; */
  margin-right: 12px;
}

/* Priority Dropdown Styling */
.kanban-priority-dropdown {
  position: relative;
  width: fit-content;
}

.kanban-priority-select {
  padding: 5px 30px 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: none;
  font-size: 0.9em;
  font-weight: bold;
  appearance: none;
  position: relative;
}
/* Priority Text Colors */
.kanban-priority-select.low {
  color: green; /* Green for Low */
}

.kanban-priority-select.medium {
  color: blue; /* Yellow for Medium */
}

.kanban-priority-select.high {
  color: orange; /* Orange for High */
}

.kanban-priority-select.urgent {
  color: red; /* Red for Urgent */
}

/* Dropdown Arrow Icon */
.kanban-priority-select::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.kanban-column-title {
  font-size: 1.5em;
  margin-bottom: 15px;
  text-align: center;
}

.kanban-priority-dropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.kanban-priority-select {
  padding: 5px 20px 5px 10px;
  border: none;
  outline: none;
  font-weight: bold;
  appearance: none;
  background-color: transparent;
  color: inherit;
}

.dropdown-icon-kanban {
  position: absolute;
  right: 0px;
  color: #555;
  pointer-events: none;
}


/* new css */
.kanban-title{
  /* padding: 7px 15px; */
  border-radius: 20px;
}
.kanban-agent-select{
  box-shadow: none !important;
  border: none !important;
  background: transparent;
  font-weight: bold;
  color: #12344d;
}

@media (max-width: 768px) {
  .kanban-wrapper {
    flex-wrap: nowrap;
  }

  .kanban-footer-agent-priority {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .kanban-priority-dropdown {
    width: 100%;
  }
}