.sidebar {
    width: 196px;
    background-color: #12344d;
    height: 100vh;
    transition: width 0.3s;
    padding-top: 20px;
    position: fixed;
    margin-top: 80px;
    /* margin-top: 65px; */
    z-index: 1000;
}

.sidebar.closed {
    width: 54px;
}

.sidebar ul {
    padding: 0;
    list-style-type: none;
    text-decoration: none !important;
}

.sidebar ul li {
    padding: 15px;
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    margin-top: 10px;
}

.sidebar ul li .icon {
    font-size: 20px;
}

.sidebar ul li span {
    margin-left: 10px;
    text-decoration: none !important;
}

.sidebar.closed ul li span {
    display: none;
}

.content {
    margin-left: 200px;
    padding: 20px;
    transition: margin-left 0.3s;
}

.sidebar.closed ~ .content {
    margin-left: 80px;
}
.SidebarComponets_TS{
text-decoration: none !important;
}
.List_Sidebar{
    text-decoration: none !important;
    color: white;
}
.pages_sidebar_Ts{
    text-decoration: none !important;
}
.no-underline {
    text-decoration: none; /* Remove underline for links */
}

.link-wrapper a, .link-wrapper a span {
    text-decoration: none !important; /* Ensure underline is removed */
}
.link-wrapper:hover{
background-color: #1e4d6f;
}

/* for active link continuously hover  */
.List_Sidebar.active {
background-color: #1e4d6f;    
}

.List_Sidebar.active:hover {
background-color: #1e4d6f;   
}

@media (max-width: 765px){
    .content {
        margin-left: 0px;
    }
    .sidebar.closed ~ .content {
        margin-left: 42px;
    }
}