.reply-container {
  /* max-width: 700px; */
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

.reply-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.email-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.toolbar .active {
  background-color: #ddd; /* Highlight active button */
  color: #333;
  border-radius: 4px;
}
.email-info label {
  font-size: 14px;
  font-weight: bold;
  width: 50px;
}

.input-field-reply {
  flex: 1;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f1f1f1;
}
.input-field-reply:hover {
  border-color: none !important;
}
.input-field-reply:focus {
  border-color: none !important;
}
.input-field-reply:active {
  border-color: none !important;
}
.input-field-reply:focus-visible {
  border-color: none !important;
}
.reply-body {
  position: relative;
  margin-bottom: 20px;
}

.text-area {
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.toolbar {
  display: flex;
  align-items: center;
  padding: 5px 0;
  gap: 10px;
  font-size: 18px;
  color: #555;
}

.toolbar .divider {
  border-left: 1px solid #ccc;
  height: 20px;
  margin: 0 5px;
}

.toolbar svg {
  cursor: pointer;
}

.toolbar svg:hover {
  color: #333;
}

.reply-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}

.send-button-reply {
  background-color: #12344d !important;
  color: white;
  padding: 6px 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.send-button-reply:hover {
  background-color: #1e4d6f !important;
}

.trash-icon {
  color: #12344d;
  font-size: 20px;
  cursor: pointer;
  border: none;
}

.trash-icon:hover {
  color: #1e4d6f;
}
.selected-file {
  border: 1px solid gray !important;
  max-width: 100%;
  width: fit-content;
  border-radius: 3px !important;
  padding: 5px !important;
}
.img_uplod_style{
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
}
.img-upload-imp{
  max-width: 20%;
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .reply-container {
    padding: 15px;
  }

  .input-field,
  .text-area {
    font-size: 13px;
  }

  .send-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .reply-container {
    padding: 10px;
  }

  .email-info label {
    width: auto;
  }

  .input-field,
  .text-area {
    font-size: 12px;
  }

  .send-button {
    font-size: 12px;
    padding: 6px 12px;
  }
}
