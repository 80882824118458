.note-container {
    width: 100%;
    /* max-width: 600px; */
    margin: auto;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-family: Arial, sans-serif;
  }
  
  .note-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
 
  
  .note-private {
    font-size: 14px;
    font-weight: bold;
  }
  
  .note-body {
    display: flex;
    flex-direction: column;
  }
  
  .note-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 14px;
    width: 100%;
  }
  
  .note-textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    resize: none;
    width: 100%;
    height: 100px;
  }
  
  .note-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .cancel-button,
  .add-note-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  .note-type-dropdown{
    box-shadow: none !important;
  }
  .cancel-button {
    background-color: #f0f0f0;
    color: #12344D;
    margin-right: 8px;
  }
  
  .add-note-button {
    background-color: #12344D;
    color: white;
  }
  
  @media (max-width: 600px) {
    .note-container {
      padding: 10px;
    }
  
    .note-input,
    .note-textarea {
      font-size: 13px;
    }
  
    .cancel-button,
    .add-note-button {
      padding: 6px 12px;
      font-size: 13px;
    }
  }
  