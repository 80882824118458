.container-graph {
  /* width: 100%; */
  overflow-x: auto;
}

.container-graph::-webkit-scrollbar {
  height: 8px;
}

.container-graph::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.container-graph::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
