/* TicketForm.css */

.ticket-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* margin-top: 20px; */
  }
  
  .ticket-form h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .form-group-ticket-form {
    margin-bottom: 15px;
  }
  
  .form-group-ticket-form label {
    margin-bottom: 5px;
    /* font-weight: bold; */
    /* color: #000; */
  }
  
  .form-group-ticket-form input,
  .form-group-ticket-form select,
  .form-group-ticket-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .form-group-ticket-form input:focus,
  .form-group-ticket-form select:focus,
  .form-group-ticket-form textarea:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none; /* Remove default outline */
  }
  
  .submit-button-ticket-form {
    background-color: #264966 !important;
    color: white !important;
    box-shadow: none !important;
    border-radius: 5px !important;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .submit-button-ticket-form:hover {
      background-color: #2c5f86 !important;
      box-shadow: none !important;
  }
  
  .cancel-button-ticket-form {
    background-color: #f44336; /* Red color */
    box-shadow: none !important;
    border-radius: 5px !important;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .cancel-button-ticket-form:hover {
    background-color: #c62828; /* Darken on hover */
    box-shadow: none !important;
    border-radius: 5px !important;
  }
  
  .required {
    color: red;
  }
  
  .buttons-ticket-form {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
  }
  