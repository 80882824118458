.container-recent-activity{
    background-color: white !important;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px 25px;
    width: 97%;
    margin: 0px 0px 0px 20px;
}
.activity-line {
    display: flex;
    flex-wrap: wrap;
    gap: 8px; /* Adjust spacing between items */
  }
  
  .activity-item {
    white-space: nowrap; /* Prevents text wrapping */
  }
  
  .child-recent-activity span {
    display: flex;
    align-items: center;
  }