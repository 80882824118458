.settings-container {
    display: flex;
    /* height: 100vh; */
    margin-left: 12px;
    margin-top: 96px;

  }
  
  .sidebar-settings {
    width: 20%;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #E4E4E7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar-settings form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .sidebar-settings label {
    cursor: pointer;
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  input[type='radio'] {
    accent-color: green;
  }
  
  .content-settings {
    width: 80%;
    padding: 20px;
    background-color: white;
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid #E4E4E7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .radio-settings{
    margin-top: 3px;
  }
  