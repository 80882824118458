


/* Main navbar styling with fixed position, full width, background color, and high z-index */
.Navbar-TicketSysteam {
    background-color: #12344d !important;
    color: white !important;
    position: fixed;
    width: 100vw;
    z-index: 1083;
    height: 84px;
}

/* Uncomment the below block if you want to switch the theme to white background */
/* 
.Navbar-TicketSysteam {
    background-color: white !important;
    color: #12344d !important;
    position: fixed;
    width: 100vw;
    z-index: 1083;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow effect for white background */

/* Navbar components (brand name, icons, etc.) styling with white color */
.Navbar-components {
    color: white !important;
    cursor: pointer !important;
}

/* Styling for nav items inside collapsed navbar (profile, bell icons) */
.navbar-subcomponents {
    color: white !important;
}

/* Specific styling for children items inside navbar (links and dropdowns) */
.Navbar-childrens {
    color: white !important;
    margin-right: 12px;
}

/* Space between items in the main navbar (logo, brand name, hamburger icon) */
.navbar-spacing {
    display: flex;
    gap: 12px; /* Adds space between items */
    margin-left: -4px; /* Slight left offset */
}

/* Hamburger icon (menu) styling with top margin for alignment */
.hamburger-navbar {
    cursor: pointer;
    margin-top: 14px;
    /* Uncomment below line to adjust icon size if needed */
    /* font-size: 1.5rem; */
}

/* Logo text style with uppercase transformation and bold font */
.Navbar-TicketSysteam .logo-text {
    display: inline;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: bold;
}

/* Hides the logo text when sidebar is closed */
.Navbar-TicketSysteam.sidebar-closed .logo-text {
    display: none;
}

/* Icon styling for ticket icon, with size and margin */
.Navbar-components .icon {
    font-size: 25px;
    margin-right: 0.2rem;
    font-weight: bold;
}

/* Bell icon styling with relative positioning for notification dot alignment */
.bell-icon {
    font-size: 1.5rem;
    position: relative;
}

/* User icon styling with similar positioning for profile dropdown */
.user-icon {
    font-size: 1.4rem;
    position: relative;
}

/* Notification dot for bell icon with blink animation */
.notification-dot {
    position: absolute;
    top: 30px; /* Adjusts vertical position relative to the bell icon */
    right: 74px; /* Adjusts horizontal position relative to the bell icon */
    width: 8px;
    height: 8px;
    background-color: #FFD700; /* Gold color for the notification dot */
    border-radius: 50%; /* Makes the dot circular */
    animation: blink 1s infinite; /* Blinking animation */
}
.droupdown-menu-childrens{
    text-align: center;
}

.notification-circle {
    position: absolute;
    top: -1px;
    right: 1px;
    width: 16px;
    height: 16px;
    /* background-color: red; */
    background-color: #FFD700;
    /* color: white; */
    color: #12344d;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding: 0;
    animation: blink 1s infinite;
}
@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.dropdown-item:hover{
background-color: gray;
color: white;

}
/* @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
} */

/* Media query for screens smaller than 765px (adjusts spacing on mobile) */
@media (max-width: 765px) {
    .navbar-spacing {
        margin-left: 16px; /* Adds a left margin to space out navbar items on smaller screens */
    }
}

