/* .tab-buttons {
    display: flex;
    margin-bottom: 20px;
    width: auto;
  
    padding: 16px;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    
  }
  
  .tab-buttons button {
    padding: 10px 20px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;

    background-color: #f5f5f5;
    cursor: pointer;
  }
  
  .tab-buttons button.active {
    background-color: gray;
    color: white;
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .btn-view-tickets{
    display: flex;
    flex-direction: row;
  }
  .filter-Ticket{
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: fixed;
    margin-top: 80px;
    width: 27%;
    overflow-y: scroll;
    height: 80%;
  }
  .left-side-col{
    margin-top: 80px;
  } */



  .tab-buttons {
    display: flex;
    margin-bottom: 20px;
    width: auto;
    /* border: 1px solid gray; */
    padding: 16px;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* position: fixed; */
    /* width: 53%; */
  }
  
  .tab-buttons button {
    padding: 10px 20px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;

    background-color: #f5f5f5;
    cursor: pointer;
  }
  
  .tab-buttons button.active {
    background-color: gray;
    color: white;
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .btn-view-tickets{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .filter-Ticket{
    /* padding: 16px; */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: fixed;
    margin-top: 80px;
    width: 27%;
    overflow-y: scroll;
    height: 80%;
  }
  .left-side-col{
    margin-top: 80px;
  }

  /* Ticket.css */

.filter-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.filter-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.close-modal {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #d9534f;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
/* Responsive styles for mobile */
@media (max-width: 768px) {
  .tab-buttons {
    flex-direction: column;
    gap: 0.5rem;
  }

  .btn-view-tickets {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr; /* 2 buttons per row */
    gap: 0.5rem;
  }

  .tab-buttons button {
    flex: 1;
    padding: 0.75rem;
    font-size: 0.9rem;
    
    white-space: nowrap; /* Ensures text stays on a single line */
  }
}

/* Further stacking for extra small screens */
@media (max-width: 480px) {
  .btn-view-tickets {
    grid-template-columns: 1fr; /* One button per row */
    gap: 0.5rem;
  }
}