/* .container-ticketScenario {
  margin-top: 95px;
  margin-left: 12px;
}

.tab-buttons button {
    padding: 8px 16px;
    margin: 4px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .tab-buttons .active {
    background-color: #007bff;
    color: white;
  }
  
  .tab-content {
    margin-top: 16px;
  }
  .tab-buttons-ticketScenario{
    display: flex;
    margin-bottom: 20px;
    width: auto;
    padding: 16px;
    justify-content: flex-start;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  }

  .tab-buttons-ticketScenario button {
    padding: 10px 20px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: whitesmoke;
    color: #12344D;
    cursor: pointer;
  }
  
  .tab-buttons-ticketScenario button.active {
    
    background-color: #12344D;
    color: white;
  }
  .tab-buttons-ticketScenario button:hover{
    background-color: #12344D;
    color: white;
  }
  
  @media (max-width: 768px) {
    .tab-buttons-ticketScenario{
      padding: -2px -13px;
      margin-right: 2px
    }
  }
  */




  .container-ticketScenario {
    margin-top: 95px;
    margin-left: 12px;
  }
  
  .tab-buttons-ticketScenario {
    display: flex;
    flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
    margin-bottom: 20px;
    padding: 16px;
    justify-content: flex-start;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .tab-buttons-ticketScenario button {
    padding: 10px 20px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: whitesmoke;
    color: #12344D;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .tab-buttons-ticketScenario button.active {
    background-color: #12344D;
    color: white;
  }
  
  .tab-buttons-ticketScenario button:hover {
    background-color: #12344D;
    color: white;
  }
  
  .tab-content {
    margin-top: 16px;
  }
  
  /* Media query for tablets */
  @media (max-width: 1024px) {
    .tab-buttons-ticketScenario {
      padding: 8px; /* Reduce padding */
      justify-content: center; /* Center buttons */
    }
  
    .tab-buttons-ticketScenario button {
      padding: 8px 16px;
      margin-right: 8px;
      font-size: 14px;
    }
  }
  
  /* Media query for mobile screens */
  @media (max-width: 768px) {
    .container-ticketScenario {
      margin-top: 60px;
      margin-left: 8px;
    }
  
    .tab-buttons-ticketScenario {
      padding: 6px; /* Adjust padding */
      justify-content: center;
    }
  
    .tab-buttons-ticketScenario button {
      padding: 6px 12px;
      margin-right: 5px;
      font-size: 12px;
      flex: 1 1 45%; /* Make buttons occupy 45% of the width on small screens */
    }
  
    /* Optional: Adjust tab-content spacing */
    .tab-content {
      margin-top: 12px;
    }
  }
  