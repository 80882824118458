/* .mail-thread-container {
    font-family: Arial, sans-serif;
    color: #333;
    margin-bottom: 20px;
  }
  
  .mail-thread-item {
    margin-left: 20px;
    border-left: 2px solid #ccc;
    padding-left: 10px;
  }
  
  .mail-thread-header {
    font-weight: bold;
    color: #0056b3;
  }
  
  .mail-thread-sender {
    color: #333;
  }
  
  .mail-thread-address {
    color: #555;
    font-size: 0.9em;
  }
  
  .mail-thread-date {
    float: right;
    font-size: 0.85em;
    color: #888;
  }
  
  .mail-thread-message {
    margin: 5px 0 15px;
    line-height: 1.4;
  }
  
  .mail-thread-replies {
    margin-top: 10px;
    padding-left: 20px;
    border-left: 1px solid #ddd;
  }
   */

   .mail-thread-header span {
    margin-right: 2px; 
  }

   .mail-thread-container {
    font-family: Arial, sans-serif;
    color: #333;
    margin-bottom: 20px;
    word-wrap: break-word;
    height: 130px;
    overflow-y: scroll;
  }
  
  .mail-thread-item {
    margin-left: 20px;
    border-left: 2px solid #ccc;
    padding-left: 10px;
  }
  
  .mail-thread-header {
    font-weight: bold;
    /* color: #0056b3; */
  }
  
  .mail-thread-sender {
    color: #333;
  }
  
  .mail-thread-address {
    color: #555;
    font-size: 0.9em;
  }
  
  .mail-thread-date {
    float: right;
    font-size: 0.85em;
    color: #888;
  }
  
  .mail-thread-message {
    margin: 5px 0 15px;
    line-height: 1.4;
  }
  .mail-thread-message img{
   width: 10%;
  }
  .mail-thread-replies {
    margin-top: 10px;
    padding-left: 20px;
    border-left: 1px solid #ddd;
  }
  .mail-thread-item {
    word-wrap: break-word;
}
  /* Media query for tablets */
  @media (max-width: 1024px) {
    .mail-thread-container {
      margin: 0 10px;
    }
    
    .mail-thread-item {
      margin-left: 15px;
      padding-left: 8px;
    }
  
    .mail-thread-date {
      font-size: 0.8em;
    }
  }
  
  /* Media query for mobile screens */
  @media (max-width: 768px) {
    .mail-thread-container {
      margin: 0 8px;
      font-size: 0.9em; /* Slightly smaller font for readability */
    }
  
    .mail-thread-item {
      margin-left: 10px;
      padding-left: 5px;
      border-left: 1px solid #ccc; /* Slightly thinner border */
    }
  
    .mail-thread-header {
      font-size: 1em;
    }
  
    .mail-thread-address,
    .mail-thread-date {
      font-size: 0.75em; /* Adjusted font size */
    }
  
    .mail-thread-replies {
      padding-left: 10px;
      border-left: 1px solid #ddd;
    }
    
    .mail-thread-message {
      margin: 4px 0 10px;
      line-height: 1.3;
    }
  }
  