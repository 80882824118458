/* .reply-container {
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
  }
  
  .reply-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .email-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .toolbar.active {
    background-color: #ddd; 
    color: #333;
    border-radius: 4px;
  }
  .email-info label {
    font-size: 14px;
    font-weight: bold;
    width: 50px;
  }
  
  .input-field-reply {
    flex: 1;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f1f1f1;
  }
  .input-field-reply:hover {
    border-color: none !important;
  }
  .input-field-reply:focus {
    border-color: none !important;
  }
  .input-field-reply:active {
    border-color: none !important;
  }
  .input-field-reply:focus-visible {
    border-color: none !important;
  }
  .reply-body {
    position: relative;
    margin-bottom: 20px;
  }
  
  .text-area {
    width: 100%;
    height: 150px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
  
  .toolbar {
    display: flex;
    align-items: center;
    padding: 5px 0;
    gap: 10px;
    font-size: 18px;
    color: #555;
  }
  
  .toolbar .divider {
    border-left: 1px solid #ccc;
    height: 20px;
    margin: 0 5px;
  }
  
  .toolbar svg {
    cursor: pointer;
  }
  .toolbar svg:active {
    color: #007bff;
  }
  
  .toolbar svg:hover {
    background-color: #e1e1e1;
    border-radius: 4px;
  }
  .reply-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
  }
  
  .send-button {
    background-color: #12344d;
    color: white;
    padding: 6px 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #1e4d6f;
  }
  
  .trash-icon {
    color: #12344d;
    font-size: 20px;
    cursor: pointer;
    border: none;
  }
  
  .trash-icon:hover {
    color: #1e4d6f;
  }

  .toolbar-button:active {
    color: #007bff; 
  }
  

  @media (max-width: 768px) {
    .reply-container {
      padding: 15px;
    }
  
    .input-field,
    .text-area {
      font-size: 13px;
    }
  
    .send-button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .reply-container {
      padding: 10px;
    }
  
    .email-info label {
      width: auto;
    }
  
    .input-field,
    .text-area {
      font-size: 12px;
    }
  
    .send-button {
      font-size: 12px;
      padding: 6px 12px;
    }
  }
   */




   /* Container */
.reply-container {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Subtle shadow for elegance */
}

/* Header */
.reply-header {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.email-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.email-info label {
  font-size: 14px;
  font-weight: 600;
  width: 50px;
  color: #333; /* Slightly darker for contrast */
}

.input-field-reply {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #f5f5f5;
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.input-field-reply:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Blue glow on focus */
}

/* Text Area */
.reply-body {
  position: relative;
  margin-bottom: 20px;
}

.text-area {
  width: 100%;
  height: 180px;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  resize: vertical;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.text-area:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Toolbar */
.toolbar {
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
  font-size: 18px;
  color: #555;
}

.toolbar .divider {
  border-left: 1px solid #ccc;
  height: 24px;
  margin: 0 5px;
}

.toolbar svg {
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.toolbar svg:hover {
  background-color: #f1f1f1;
  border-radius: 4px;
  color: #007bff;
}

/* Footer */
.reply-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.send-button {
  background-color: #007bff;
  color: white;
  padding: 8px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.send-button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
}

.trash-icon {
  color: #ff4757;
  font-size: 20px;
  cursor: pointer;
}

.trash-icon:hover {
  color: #e84118;
}

/* Responsive */
@media (max-width: 768px) {
  .reply-container {
      padding: 15px;
  }

  .input-field-reply,
  .text-area {
      font-size: 13px;
  }

  .send-button {
      font-size: 14px;
      padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .reply-container {
      padding: 10px;
  }

  .email-info label {
      width: auto;
  }

  .input-field-reply,
  .text-area {
      font-size: 12px;
  }

  .send-button {
      font-size: 12px;
      padding: 6px 12px;
  }
}
