/* EditProfile.css */

/* Fullscreen container for the edit panel */
.edit-profile-container {
    margin-top: 70px;
    position: fixed;
    top: 0;
    right: 0;
    width: 40%; /* Adjust width as needed */
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
    transform: translateX(100%); /* Start off-screen */
    transition: transform 0.3s ease-in-out;
    z-index: 1050;
    border-radius: 5px;
  }
  /* Slide-in animation */
.slide-in {
    transform: translateX(0); /* Slide in to visible position */
  }
  
  /* Slide-out animation */
  .slide-out {
    transform: translateX(100%); /* Slide out off-screen */
  }
  /* Content inside the edit panel */
  .edit-profile-content {
    padding: 2rem;
  }
  
  /* Form styling */
  .form-group {
    margin-bottom: 1.5rem;
  }
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
  }
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  /* Button group styling */
  .button-group {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
  }
  
  /* Cancel button styling */
  .btn-cancel {
    background-color: gray;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-cancel:hover {
    background-color: gray;
  }
  
  /* Update button styling */
  .btn-update {
    background-color: #12344D;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-update:hover {
    background-color: #1e4d6f;
  }
  