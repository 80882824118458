/* Profile.css */

/* Container and Row Setup */
.container-fluid {
    padding: 1rem;
  }
  
  .row {
    display: flex;
    justify-content: start; /* Align to the left */
  }
  
  /* Column Setup */
  /* .col-12 {
    width: 100%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-md-4 {
    width: 33.33%;
  }
  .col-lg-4 {
    width: 25%;
  } */
  
  /* Card Styling */
  .user-card-container {
    padding-left: 1rem;
  }
  .user-card {
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  
  /* Avatar Styling */
  .user-avatar {
    width: 80px;
    height: 80px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #d9d2d2;
    font-size: 36px;
    color: #12344d;
  }
  
  /* User Information */
  .card-body-profile {
    text-align: center;
  }
  .user-name {
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .user-email {
    color: #6c757d;
    margin-bottom: 1rem;
  }
  
  /* Edit Button Styling */
  .btn-edit {
    border: 1px solid #6c757d;
    background-color: transparent;
    color: #6c757d;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  .btn-edit:hover {
    background-color: #6c757d;
    color: #fff;
  }
  .profile-container{
    margin-top: 80px;
  }