.form-control-department{
    box-shadow: none !important;
}

.form-control-department::placeholder{
    font-size: 14px;
    /* padding-bottom: 5px; */
    position: relative;
    bottom: 3px;
    /* color: brown; */
}

.form-control::placeholder{
    font-size: 14px;
    position: relative;
    bottom: 3px;
}