.Main_TotalMenu_HeyMaria{
    display: flex;
}
.containerHeroDashboard{
    /* padding: 15px 0px !important; */
    margin-top: 60px;
}
.menu-Item-Graph{
    width: 20%;
    
}
.TotalMenu_HeyMaria{
    width: 100%;
    padding: 20px 10px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #E4E4E7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.SubMenu_HeyMaria{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.value_HeyMaria{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
    line-height: 40.47px;
    color: #000000;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.label_HeyMaria{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    line-height: 16.86px;
    color: #000000;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.Head_Text-HeyMaria{
    /* font-family: 'Plus Jakarta Sans'; */
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-left: 3px;
}
.Sub_Text-HeyMaria{
    /* font-family: 'Plus Jakarta Sans'; */
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #71717A;
}

@media (max-width: 765px){
    .Main_TotalMenu_HeyMaria{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .menu-Item-Graph{
        width: 100%;
    }
}