.button_collaboration {
  padding: 10px 20px;
  margin-right: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  background-color: #12344d;
  color: white;
  cursor: pointer;
  border: none;
}
.btn-collabration-main{
  display: flex;
  justify-content: space-between;
}
/* display: flex;
    justify-content: space-between; */
