
.container-listview-main{
  height: 400px;
  overflow: auto;
}
.ticket-card {
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ticket-title {
    font-size: 1.1rem;
  }
  
  .status-badge {
    font-size: 0.9rem;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .status-badge.new {
    background-color: #28a745;
    color: #fff;
  }
  
  .status-badge.first-response-due {
    background-color: orange;
    color: #fff;
  }
  
  .status-badge.overdue {
    background-color: #dc3545;
    color: #fff;
  }
  
  .priority-status {
    font-size: 1rem;
    cursor: pointer;
  }
  
  .ticket-details {
    margin-top: 10px;
  }

.disabled-content {
  opacity: 0.5;
  pointer-events: none;
}

.ticket-card .priority-status,
.ticket-card .due-status-dropdown {
  pointer-events: auto;
  opacity: 1;
}
  
  .ticket-status {
    cursor: pointer;
  }
  .Status-badge-ListView{
    display: flex;
    gap: 16px;
  }




  .custom-dropdown-menu {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 10%; 
    right: -1%;
    top: 22%;
    text-align: center;
  }
  
  .custom-dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .custom-dropdown-item:hover {
    background: #f0f0f0;
  }
  
  .custom-dropdown-item.low {
    color: green;
  }
  
  .custom-dropdown-item.medium {
    color: blue;
  }
  
  .custom-dropdown-item.high {
    color: orange;
  }
  
  .custom-dropdown-item.urgent {
    color: red;
  }
  .priority-low {
    color: green; 
  }
  
  .priority-medium {
    color: blue; 
  }
  
  .priority-high {
    color: orange; 
  }
  
  .priority-urgent {
    color: red; 
  }
  
 
  .ticket-card {
    position: relative; 
  }
  


  .custom-dropdown-menu-status {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: 10%; 
    right: -1%;
    top: 94%;
    text-align: center;
    cursor: pointer;
  }
  
  .custom-dropdown-item {
    padding: 8px 12px;
    cursor: pointer; 
  }
  
  .custom-dropdown-item:hover {
    background-color: #f0f0f0; 
  }

  .agent-dropdown{
    border: none;
    cursor: pointer;
    font-weight: bold;
  color: #12344d;
  } 
.watch-icon{
font-size: 19px;
}
  /* for disable  */
  /* Add this in ListView.css */
/* .disabled-card {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}

Keep dropdown menu clickable when card is disabled
.ticket-card .due-status-dropdown {
  pointer-events: auto;
  cursor: pointer;
  z-index: 1000;
}



/* Ticket Card Styles
.ticket-card {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;
  transition: transform 0.2s ease-in-out;
}

.ticket-card:hover {
  transform: scale(1.02);
}

.ticket-title {
  font-size: 1.1rem;
  font-weight: bold;
}

.ticket-details {
  margin-top: 10px;
}

Status Badge Styles
.status-badge {
  font-size: 0.9rem;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
}

.status-badge.new {
  background-color: #28a745;
}

.status-badge.first-response-due {
  background-color: orange;
}

.status-badge.overdue {
  background-color: #dc3545;
}

.Status-badge-ListView {
  display: flex;
  gap: 16px;
}

Priority and Due Status Styling
.priority-status {
  font-size: 1rem;
  cursor: pointer;
}

.ticket-status {
  cursor: pointer;
  font-weight: bold;
  color: #007bff;
}

Disabled Content Styling
.disabled-content {
  opacity: 0.5;
  pointer-events: none;
}

.ticket-card .priority-status,
.ticket-card .due-status-dropdown {
  pointer-events: auto;
  opacity: 1;
}

Custom Dropdown Menu
.custom-dropdown-menu,
.custom-dropdown-menu-status {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 16%;
  text-align: center;
}

.custom-dropdown-menu {
  right: -1%;
  top: 22%;
}

.custom-dropdown-menu-status {
  right: 1%;
  top: 94%;
}

Custom Dropdown Items
.custom-dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.custom-dropdown-item:hover {
  background-color: #f0f0f0;
}

.custom-dropdown-item.low {
  color: green;
}

.custom-dropdown-item.medium {
  color: blue;
}

.custom-dropdown-item.high {
  color: orange;
}

.custom-dropdown-item.urgent {
  color: red;
}

Priority Colors
.priority-low {
  color: green;
}

.priority-medium {
  color: blue;
}

.priority-high {
  color: orange;
}

.priority-urgent {
  color: red;
}

Agent Dropdown
.agent-dropdown {
  border: none;
}

Responsive Styling
@media (max-width: 768px) {
  .ticket-content {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .dropdowns {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
  }

  .custom-dropdown-menu,
  .custom-dropdown-menu-status {
    width: 100%;
    right: 0;
    top: auto;
    bottom: 0;
  }
} */
.dropdown-options {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  width: 10%;
  z-index: 10;
  text-align: center;
  right: 1%;
}

.dropdown-option {
  padding: 8px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}
.message-mail-list p{
  margin-bottom: 0;
}
.due-status-dropdown{
  font-weight: bold;
  color: #12344d;
}