/* Custom modal styling */
.filters-tickets-modal .modal-dialog {
  /* max-width: 90vw;
  width: 100%; */
}

.filters-tickets-modal .modal-content {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: hidden;
}
.filters-tickets-modal .modal-body{
  overflow: auto;
}
.modal-header-sticky {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
  /* padding: 1rem; */
  border-bottom: 1px solid #dee2e6;
}

.modal-body-scrollable {
  overflow-y: auto;
  padding: 1rem;
  flex-grow: 1;
}

.modal-footer-sticky {
  position: sticky;
  bottom: 0;
  z-index: 2;
  background-color: #fff;
  /* padding: 1rem; */
  border-top: 1px solid #dee2e6;
}

/* Adjust modal width for small screens */
@media (max-width: 576px) {
  .filters-tickets-modal .modal-dialog {
    max-width: 80vw;
    margin-left: 60px;
  }

  .filters-tickets-modal .modal-content {
    padding: 0.5rem; /* Reduce padding to save space */
  }

  .modal-header-sticky,
  .modal-footer-sticky {
    padding: 0.75rem; /* Reduce padding for header and footer */
  }
}